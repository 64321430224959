<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">{{ heading }}</h1>

    <div class="box box--white box--top mt-16">
      <div class="form-group box__item">
        <div class="form-label d-flex mb-8">
          <label class="text-warm">Plan name</label>
          <span class="text-warm">{{ planParams.title.length }}/15</span>
        </div>
        <input
          v-model="$v.planParams.title.$model"
          maxlength="15"
          autofocus
          type="text"
          :class="['form-control', { 'form-control--error': $v.planParams.title.$anyError }]"
        />

        <p v-if="$v.planParams.title.$anyError" class="form-error-text">
          <span v-if="!$v.planParams.title.required">Plan name can't be blank</span>
          <span v-if="!$v.planParams.title.maxLength">Plan name is too long</span>
        </p>
      </div>

      <div class="form-group box__item mt-0">
        <label class="d-block mb-8 text-warm">Hold period (months)</label>
        <input
          v-model="$v.planParams.period.$model"
          type="number"
          min="0"
          :class="[
            'form-control form-control--sm text-center',
            { 'form-control--error': $v.planParams.period.$anyError }
          ]"
        />
        <p v-if="$v.planParams.period.$anyError" class="form-error-text">
          <span v-if="!$v.planParams.period.required">Plan period can't be blank</span>
          <span v-if="!$v.planParams.period.minValue">Plan period at least 1 month</span>
        </p>
      </div>
    </div>

    <div class="table-wrapper mt-16">
      <table class="table table--white">
        <thead>
          <tr>
            <th>No.</th>
            <th>Interest Rate<br />(%)</th>
            <th>Min. Amount<br />(TOKO)</th>
            <th>Max. Amount<br />(TOKO)</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in planParams.interests" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <div class="form-group">
                <input
                  v-model="item.rate"
                  type="number"
                  min="0"
                  :class="[
                    'form-control form-control--xs text-center',
                    { 'form-control--error': item.error && item.error['rate'] }
                  ]"
                />
                <p v-if="item.error && item.error['rate']" class="form-error-text">{{ item.error['rate'] }}</p>
              </div>
            </td>
            <td>
              <div class="form-group">
                <currency-input
                  v-model="item.min_amount"
                  target="numeric"
                  :class="[
                    'form-control form-control--auto text-center',
                    { 'form-control--error': item.error && item.error['rate'] }
                  ]"
                />
                <p v-if="item.error && item.error['min_amount']" class="form-error-text">
                  {{ item.error['min_amount'] }}
                </p>
              </div>
            </td>
            <td>
              <div class="form-group">
                <currency-input
                  v-model="item.max_amount"
                  target="numeric"
                  :class="[
                    'form-control form-control--auto text-center',
                    { 'form-control--error': item.error && item.error['max_amount'] }
                  ]"
                />
                <p v-if="item.error && item.error['max_amount']" class="form-error-text">
                  {{ item.error['max_amount'] }}
                </p>
              </div>
            </td>
            <td>
              <button tabindex="-1" class="btn btn--clear" @click="onConfirmRemoveInterest(index)">
                <toko-trash-icon />
              </button>
            </td>
          </tr>

          <tr>
            <td colspan="4">
              <button @click="addInterest" class="btn btn--clear text-purple">Add More Row</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-right btn-group mt-16">
      <button @click="$router.go(-1)" class="btn btn--purple-bordered">Cancel</button>
      <button class="btn btn--primary" @click="onSave" :disabled="!isPlanFill">Save</button>
    </div>

    <confirm-dialog
      v-if="isRemoveInterestModalOpen"
      :open.sync="isRemoveInterestModalOpen"
      confirmText="Are you sure remove this row?"
      confirmButtonText="Remove"
      @confirm="removeInterest"
    />
  </div>
</template>

<script>
import planGeneralFormValidator from '@/validations/plan-general-form'
import { deepCopy, isObjEqual } from '@/utils/json-parser'
import TokoTrashIcon from '@/ui/toko-icons/TokoTrash'
import ConfirmDialog from '@/components/ConfirmDialog'
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'PlanForm',

  data() {
    return {
      planParams: {
        title: '',
        period: '',
        interests: []
      },
      initPlanParams: {
        title: '',
        period: '',
        interests: []
      },
      initInterest: {
        rate: '',
        min_amount: '',
        max_amount: '',
        error: null,
        disable: false
      },
      interrestTarget: null,
      interestCount: 6, // initital interest items count
      isRemoveInterestModalOpen: false
    }
  },

  props: {
    heading: {
      type: String,
      default: 'Add new plan'
    },

    edit: {
      type: Boolean,
      default: false
    },

    plan: {
      type: Object,
      require: false
    }
  },

  mixins: [planGeneralFormValidator],

  components: {
    CurrencyInput,
    ConfirmDialog,
    TokoTrashIcon
  },

  computed: {
    isPlanFill() {
      return !isObjEqual(this.planParams, this.initPlanParams)
    }
  },

  methods: {
    generateInterestItems() {
      for (let i = 0; i < this.interestCount; i++) {
        this.planParams.interests.push({ ...this.initInterest })
        this.initPlanParams.interests.push({ ...this.initInterest })
      }
    },

    onRemoveInterest() {
      this.planParams.interests.splice(this.interrestTarget, 1)
      this.initPlanParams.interests.splice(this.interrestTarget, 1)
    },

    onConfirmRemoveInterest(index) {
      this.interrestTarget = index
      if (isObjEqual(this.planParams.interests[index], this.initPlanParams.interests[index])) {
        this.onRemoveInterest()
      } else {
        this.isRemoveInterestModalOpen = true
      }
    },

    addInterest() {
      this.planParams.interests.push({ ...this.initInterest })
      this.initPlanParams.interests.push({ ...this.initInterest })
    },

    removeInterest() {
      this.onRemoveInterest()
      this.isRemoveInterestModalOpen = false
    },

    onSave() {
      this.$v.$touch()
      const interestItems = deepCopy(this.planParams.interests)
      this.planParams.interests = this.$planValidate(interestItems).validate()
    },

    initPlan() {
      if (this.plan) {
        Object.keys(this.planParams).forEach(key => {
          this.planParams[key] = deepCopy(this.plan[key])
          this.initPlanParams[key] = deepCopy(this.plan[key])
        })
      } else {
        this.generateInterestItems()
      }
    }
  },

  created() {
    this.initPlan()
  }
}
</script>
