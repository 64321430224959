import { required, maxLength, minValue } from 'vuelidate/lib/validators'

export default {
  validations: {
    planParams: {
      title: {
        required,
        maxLength: maxLength(16)
      },

      period: {
        required,
        minValue: minValue(1)
      }
    }
  }
}
