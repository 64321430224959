var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"headline--md text-bold text-uppercase"},[_vm._v(_vm._s(_vm.heading))]),_c('div',{staticClass:"box box--white box--top mt-16"},[_c('div',{staticClass:"form-group box__item"},[_c('div',{staticClass:"form-label d-flex mb-8"},[_c('label',{staticClass:"text-warm"},[_vm._v("Plan name")]),_c('span',{staticClass:"text-warm"},[_vm._v(_vm._s(_vm.planParams.title.length)+"/15")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.planParams.title.$model),expression:"$v.planParams.title.$model"}],class:['form-control', { 'form-control--error': _vm.$v.planParams.title.$anyError }],attrs:{"maxlength":"15","autofocus":"","type":"text"},domProps:{"value":(_vm.$v.planParams.title.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.planParams.title, "$model", $event.target.value)}}}),(_vm.$v.planParams.title.$anyError)?_c('p',{staticClass:"form-error-text"},[(!_vm.$v.planParams.title.required)?_c('span',[_vm._v("Plan name can't be blank")]):_vm._e(),(!_vm.$v.planParams.title.maxLength)?_c('span',[_vm._v("Plan name is too long")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group box__item mt-0"},[_c('label',{staticClass:"d-block mb-8 text-warm"},[_vm._v("Hold period (months)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.planParams.period.$model),expression:"$v.planParams.period.$model"}],class:[
          'form-control form-control--sm text-center',
          { 'form-control--error': _vm.$v.planParams.period.$anyError }
        ],attrs:{"type":"number","min":"0"},domProps:{"value":(_vm.$v.planParams.period.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.planParams.period, "$model", $event.target.value)}}}),(_vm.$v.planParams.period.$anyError)?_c('p',{staticClass:"form-error-text"},[(!_vm.$v.planParams.period.required)?_c('span',[_vm._v("Plan period can't be blank")]):_vm._e(),(!_vm.$v.planParams.period.minValue)?_c('span',[_vm._v("Plan period at least 1 month")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"table-wrapper mt-16"},[_c('table',{staticClass:"table table--white"},[_vm._m(0),_c('tbody',[_vm._l((_vm.planParams.interests),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.rate),expression:"item.rate"}],class:[
                  'form-control form-control--xs text-center',
                  { 'form-control--error': item.error && item.error['rate'] }
                ],attrs:{"type":"number","min":"0"},domProps:{"value":(item.rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "rate", $event.target.value)}}}),(item.error && item.error['rate'])?_c('p',{staticClass:"form-error-text"},[_vm._v(_vm._s(item.error['rate']))]):_vm._e()])]),_c('td',[_c('div',{staticClass:"form-group"},[_c('currency-input',{class:[
                  'form-control form-control--auto text-center',
                  { 'form-control--error': item.error && item.error['rate'] }
                ],attrs:{"target":"numeric"},model:{value:(item.min_amount),callback:function ($$v) {_vm.$set(item, "min_amount", $$v)},expression:"item.min_amount"}}),(item.error && item.error['min_amount'])?_c('p',{staticClass:"form-error-text"},[_vm._v(" "+_vm._s(item.error['min_amount'])+" ")]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"form-group"},[_c('currency-input',{class:[
                  'form-control form-control--auto text-center',
                  { 'form-control--error': item.error && item.error['max_amount'] }
                ],attrs:{"target":"numeric"},model:{value:(item.max_amount),callback:function ($$v) {_vm.$set(item, "max_amount", $$v)},expression:"item.max_amount"}}),(item.error && item.error['max_amount'])?_c('p',{staticClass:"form-error-text"},[_vm._v(" "+_vm._s(item.error['max_amount'])+" ")]):_vm._e()],1)]),_c('td',[_c('button',{staticClass:"btn btn--clear",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.onConfirmRemoveInterest(index)}}},[_c('toko-trash-icon')],1)])])}),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('button',{staticClass:"btn btn--clear text-purple",on:{"click":_vm.addInterest}},[_vm._v("Add More Row")])])])],2)])]),_c('div',{staticClass:"text-right btn-group mt-16"},[_c('button',{staticClass:"btn btn--purple-bordered",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn--primary",attrs:{"disabled":!_vm.isPlanFill},on:{"click":_vm.onSave}},[_vm._v("Save")])]),(_vm.isRemoveInterestModalOpen)?_c('confirm-dialog',{attrs:{"open":_vm.isRemoveInterestModalOpen,"confirmText":"Are you sure remove this row?","confirmButtonText":"Remove"},on:{"update:open":function($event){_vm.isRemoveInterestModalOpen=$event},"confirm":_vm.removeInterest}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("No.")]),_c('th',[_vm._v("Interest Rate"),_c('br'),_vm._v("(%)")]),_c('th',[_vm._v("Min. Amount"),_c('br'),_vm._v("(TOKO)")]),_c('th',[_vm._v("Max. Amount"),_c('br'),_vm._v("(TOKO)")]),_c('th')])])}]

export { render, staticRenderFns }